import React, { lazy } from 'react';

const SubcontractClaimListing = lazy(() =>
  import('../SubconClaimV2/SubcontractClaimListing').then(module => ({
    default: module.SubcontractClaimListing,
  })),
);

const SubconRetClaimDetail = lazy(() =>
  import('../SubconDetail/SubconRetClaimDetail').then(module => ({
    default: module.SubconRetClaimDetail,
  })),
);

const ClaimAppproveView = lazy(() =>
  import('../SubconClaimV2/SubcontractClaimApproveViewForm').then(module => ({
    default: module.SubcontractClaimApproveViewForm,
  })),
);

const ClaimRejectView = lazy(() =>
  import('../SubconClaimV2/SubcontractClaimCancelledRejectedForm').then(
    module => ({
      default: module.SubcontractClaimCancelledRejectedForm,
    }),
  ),
);

const ClaimSubmitView = lazy(() =>
  import('../SubconClaimV2/SubcontractClaimSubmitViewForm').then(module => ({
    default: module.SubcontractClaimSubmitViewForm,
  })),
);

const ClaimCancel = lazy(() =>
  import('../SubconClaimV2/SubcontractClaimCancelledViewForm').then(module => ({
    default: module.SubcontractClaimCancelledViewForm,
  })),
);

const SubconManagementClaimRoutes = [
  {
    props: {
      exact: true,
      path: `/subcontract/submenu/:ID/account-status/retention-claims`,
    },
    component: (
      <SubcontractClaimListing
        accountType="subcontract"
        transactionType="retention-claims"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/subcontractor-account/account-transaction/:ID/retention-claims',
    },
    component: (
      <SubcontractClaimListing
        accountType="subcontractor-account"
        transactionType="retention-claims"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontract/submenu/:ID/account-status/retention-claims/detail/:transactionID',
    },
    component: (
      <SubconRetClaimDetail
        accountType="subcontract"
        transactionType="retention-claims"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontract/submenu/:ID/account-status/progress-claims`,
    },
    component: (
      <SubcontractClaimListing
        accountType="subcontract"
        transactionType="progress-claims"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontract/submenu/:ID/account-status/progress-claims/:transactionID/view-draft',
    },
    component: (
      <ClaimSubmitView
        accountType="subcontractor-account"
        formMode="view-submit"
        viewType="subcontract"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontract/submenu/:ID/account-status/progress-claims/:transactionID/rejected',
    },
    component: <ClaimRejectView formMode="rejected" viewType="subcontract" />,
  },
  {
    props: {
      exact: true,
      path:
        '/subcontract/submenu/:ID/account-status/progress-claims/:transactionID/view-submit',
    },
    component: (
      <ClaimSubmitView
        accountType="subcontractor-account"
        formMode="view-submit"
        viewType="subcontract"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontract/submenu/:ID/account-status/progress-claims/:transactionID/view-approve',
    },
    component: (
      <ClaimAppproveView
        accountType="subcontractor-account"
        formMode="view-approve"
        viewType="subcontract"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontract/submenu/:ID/account-status/progress-claims/:transactionID/cancelled',
    },
    component: (
      <ClaimCancel accountType="subcontractor-account" viewType="subcontract" />
    ),
  },
];

export default SubconManagementClaimRoutes;
