import OrangeDollarIcon from '@ifca-root/react-component/src/assets/icons/Money/dollar-orange.svg';
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import CardExpansion from '@ifca-root/react-component/src/components/CardList/CardExpansion';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { ErrorDialog } from '@ifca-root/react-component/src/components/Dialog/ErrorDialog';
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import IconText from '@ifca-root/react-component/src/components/Typography/IconText';
import TextSeparator from '@ifca-root/react-component/src/components/Typography/TextSeparator';
import {
  amtNumStr,
  amtStr,
} from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@material-ui/core';
import BigNumber from 'bignumber.js';
import { POFormProp } from 'containers/ProjectPurchaseModule/helper/POYupFunction';
import { useGetWbsParentChildLazyQuery } from 'generated/graphql';
import { addressFormat } from 'helpers/AddressHandler/AddressFormat';
import { amtStr4Dec } from 'helpers/StringNumberFunction/numFormatter';
import { IAction } from 'models';
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

interface IGenerateDialog {
  poFormState: POFormProp;
  dispatch: Dispatch<IAction<string, any>>;
  finalPOInput?: any;
  specialInstruction: any[];
  docRef: any[];
  contractID?: string;
  setDocRef: Dispatch<SetStateAction<any[]>>;
  setInstructions: Dispatch<SetStateAction<any[]>>;
  handleSubmit?: (data: any) => void;
  handlePreviewPDF: () => void;
}

export const POFormGenerateDialog = (props: IGenerateDialog) => {
  const {
    poFormState,
    dispatch,
    finalPOInput,
    setInstructions,
    specialInstruction,
    docRef,
    setDocRef,
    handlePreviewPDF,
    contractID,
    handleSubmit,
  } = props;

  const [instructionInput, setInstructionInput] = useState(false);
  const [docRefInput, setDocRefInput] = useState(false);

  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (!!contractID)
      fetchWBSParentChild({ variables: { contractID: contractID } });
  }, [finalPOInput?.poItems?.any(v => !!v?.wbsID), contractID]);

  const [
    fetchWBSParentChild,
    {
      loading: wbsParentChildLoading,
      error: wbsParentChildError,
      data: { getWBSParentChild } = { getWBSParentChild: [] },
    },
  ] = useGetWbsParentChildLazyQuery({
    fetchPolicy: 'network-only',
    onError: e => {
      let error = e.message.substring(15);
      setErrorDialog(true);
      setErrorMsg(error);
    },
  });

  const getTotalPOAmt = (arrData: any[]) => {
    return arrData?.reduce((total, item) => {
      // let itemAmt =
      //   parseFloat(amtNumStr(item?.totalPOAmount)) > 0
      //     ? parseFloat(amtNumStr(item?.totalPOAmount))
      //     : 0;
      // return new BigNumber(total || 0).plus(itemAmt || 0).toNumber();
      return new BigNumber(total || 0)
        .plus(item?.totalPOAmount || 0)
        .toNumber();
    }, 0);
  };

  return (
    <>
      {wbsParentChildLoading && <Loading />}
      <CommonDialog
        fullWidth={true}
        open={poFormState.generatedPoDialog}
        onClose={() => {
          dispatch({
            type: 'generatedPoDialog',
            payload: !poFormState?.generatedPoDialog,
          });
        }}
        sections={{
          header: {
            title: `Generated PO (${finalPOInput?.length})`,
            isAmount: true,
            rightText: amtStr(getTotalPOAmt(finalPOInput)),
          },
          body: () => (
            <>
              {finalPOInput?.map((v, i) => {
                const selectedSupplierName = !!v?.negoSupplySelected
                  ? v?.negoSupplySelected?.supplierName
                  : !!v?.rfqSubmissionSelected
                  ? v?.rfqSubmissionSelected?.supplierName
                  : 'N/A';
                return (
                  <CardExpansion
                    summary={
                      <ListItem key={i}>
                        <ListItemText
                          primary={
                            <>
                              <span className="mdLabel flex-space">
                                {selectedSupplierName}
                              </span>
                              <IconText
                                icon={
                                  <img
                                    src={OrangeDollarIcon}
                                    style={{ width: '12px' }}
                                  />
                                }
                                font="xxTitle highlight-text"
                                children={amtStr(v?.totalPOAmount)}
                              />
                            </>
                          }
                          secondary={
                            <span className="desc text-noflow">
                              {`${v?.purchaseReq?.deliveryAddress?.name} 
                              - 
                              ${addressFormat(
                                v?.purchaseReq?.deliveryAddress?.address,
                              )}`}
                            </span>
                          }
                        />
                      </ListItem>
                    }
                  >
                    <TextField
                      fullWidth
                      name="instructions"
                      label="Special Instruction"
                      className="m-b-10"
                      value={specialInstruction[i] ?? ''}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const newArr = finalPOInput;

                        let selected = newArr[i];

                        let temp = specialInstruction;
                        specialInstruction[i] = e.target.value;
                        setInstructions([...temp]);

                        if (e.target.value) setInstructionInput(true);
                        else setInstructionInput(false);
                      }}
                    />
                    <div className="rm-padding table-wrap p-t-10">
                      <List className="core-list-noShadow content-wrap full">
                        <ListItem className="table-header">
                          <ListItemText
                            primary={
                              <Grid container className="fw-thin xxTitle">
                                <Grid
                                  component="span"
                                  item
                                  xs={1}
                                  className="center-text"
                                >
                                  No.
                                </Grid>
                                <Grid container item xs={11}>
                                  <Grid
                                    component="span"
                                    item
                                    xs={12}
                                    className="flex-space"
                                  >
                                    Item
                                  </Grid>
                                  <Grid
                                    component="span"
                                    item
                                    xs={3}
                                    className="left-text"
                                  >
                                    Price
                                  </Grid>
                                  <Grid
                                    component="span"
                                    item
                                    xs={3}
                                    className="center-text"
                                  >
                                    Tax
                                  </Grid>
                                  <Grid
                                    component="span"
                                    item
                                    xs={3}
                                    className="center-text"
                                  >
                                    Qty
                                  </Grid>
                                  <Grid
                                    component="span"
                                    item
                                    xs={3}
                                    className="right-text"
                                  >
                                    UOM
                                  </Grid>
                                </Grid>
                              </Grid>
                            }
                          />
                        </ListItem>
                        {v?.poItems?.length > 0 &&
                          v?.poItems?.map((el, index) => {
                            return (
                              <POItemDetails
                                el={el}
                                index={index}
                                getWBSParentChild={getWBSParentChild}
                              />
                            );
                          })}
                      </List>
                    </div>
                  </CardExpansion>
                );
              })}
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () =>
                    dispatch({
                      type: 'generatedPoDialog',
                      payload: !poFormState.generatedPoDialog,
                    }),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Submit',
                props: {
                  onClick: () => {
                    dispatch({
                      type: 'purchaseOrderInput',
                      payload: finalPOInput,
                    });
                    handleSubmit(finalPOInput);
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <ErrorDialog
        errorDia={errorDialog}
        setErrorDia={setErrorDialog}
        errorMsg={errorMsg}
        errorHeaderMsg={`Purchase Order Form Dialog Access Error`}
      />
    </>
  );
};

interface IBudgetDialog {
  poFormState: POFormProp;
  dispatch: Dispatch<IAction<string, any>>;
  purchaseBudget?: any;
  purchaseBudgetApproval?: any;
  purchaseBudgetAllocation?: any;
  handleEditUpload?: (file: any) => Promise<void>;
  remove?: (index: any) => void;
  previewFiles?: any[];
  handleUploadChange?: (e: any) => void;
  files?: any[];
  budgetChecker?: any;
  contractID?: string;
}

export const ExceedBudgetDialog = (props: IBudgetDialog) => {
  const { poFormState, dispatch, purchaseBudget } = props;

  return (
    <CommonDialog
      fullWidth={true}
      open={poFormState.budgetExcDialog}
      onClose={() =>
        dispatch({
          type: 'budgetExcDialog',
          payload: !poFormState.budgetExcDialog,
        })
      }
      sections={{
        header: {
          title: 'Budget Exceeded',
        },
        body: () => (
          <>
            <div className="content-wrap full">
              <div className="xxTitle"> Amount exceeded budget as below:</div>
            </div>
            {purchaseBudget
              ?.filter(v => v?.isExceeded === true)
              .map((el, index) => (
                <CardContents key={index}>
                  <div className="content-wrap full">
                    <div className="xxTitle color-blue">{el.itemName}</div>
                  </div>
                  <div className="content-wrap ">
                    <div className="desc">Total PO To Date</div>
                    <div className="xxTitle"> {amtStr(el.poToDate)}</div>
                  </div>
                  <div className="content-wrap ">
                    <div className="desc">This PO</div>
                    <div className="xxTitle"> {amtStr(el.currentPOAmt)}</div>
                  </div>
                  <div className="content-wrap ">
                    <div className="desc">Total Amount</div>
                    <div className="xxTitle"> {amtStr(el.totalPOAmt)}</div>
                  </div>
                  <div className="content-wrap ">
                    <div className="desc">Budgeted Amount</div>
                    <div className="xxTitle"> {amtStr(el.budgetAmt)}</div>
                  </div>
                </CardContents>
              ))}
            <div className="content-wrap full">
              <div className="xxTitle">Proceed to generate PO?</div>
            </div>
          </>
        ),
        footer: {
          actions: [
            {
              displayText: 'Close',
              props: {
                onClick: () =>
                  dispatch({ type: 'budgetExcDialog', payload: false }),
                variant: 'contained',
                color: 'primary',
              },
            },
            {
              displayText: 'Proceed',
              props: {
                onClick: () => {
                  dispatch({ type: 'generatedPoDialog', payload: true });
                  dispatch({ type: 'budgetExcDialog', payload: false });
                },

                variant: 'contained',
                color: 'primary',
              },
            },
          ],
        },
      }}
    />
  );
};

export const NoBudgetWarningDialog = (props: IBudgetDialog) => {
  const {
    dispatch,
    poFormState,
    purchaseBudget,
    purchaseBudgetAllocation,
    budgetChecker,
    contractID,
  } = props;

  return (
    <CommonDialog
      fullWidth={true}
      open={poFormState.noBudgetWarningDialog}
      onClose={() =>
        dispatch({
          type: 'noBudgetWarningDialog',
          payload: !poFormState.noBudgetWarningDialog,
        })
      }
      sections={{
        header: { title: 'Budget Warning!' },
        body: () => (
          <div className="mdDesc text-noflow">
            The following item(s) is not in budget, would you like to proceed?
            <List>
              {purchaseBudgetAllocation
                ?.filter(x => !x?.isAllocated)
                ?.map((v, i) => (
                  <ListItem key={i}>
                    <ListItemText
                      primary={
                        <span className="mdDesc flex-space text-noflow">
                          {`${i + 1}. ${v?.itemName}`}
                        </span>
                      }
                    />
                  </ListItem>
                ))}
            </List>
          </div>
        ),
        footer: {
          actions: [
            {
              displayText: 'Close',
              props: {
                onClick: () =>
                  dispatch({ type: 'noBudgetWarningDialog', payload: false }),
                variant: 'contained',
                color: 'primary',
              },
            },
            {
              displayText: 'Proceed',
              props: {
                onClick: () => {
                  {
                    budgetChecker({
                      variables: {
                        contractID: contractID,
                        prInfo: poFormState.prItem
                          .filter(v => v?.checked === true)
                          .map(el => {
                            return {
                              prItemID: el.ID,
                              totalAmt: parseFloat(amtNumStr(el.totalAmount)),
                            };
                          }),
                      },
                    });
                    dispatch({ type: 'noBudgetWarningDialog', payload: false });
                  }
                },
                variant: 'contained',
                color: 'primary',
              },
            },
          ],
        },
      }}
    />
  );
};

export const BudgetMutationDialog = (props: IBudgetDialog) => {
  const {
    dispatch,
    poFormState,
    purchaseBudget,
    purchaseBudgetApproval,
    files,
    handleEditUpload,
    handleUploadChange,
    previewFiles,
    remove,
  } = props;
  return (
    <CommonDialog
      fullWidth={true}
      open={poFormState.budgetMutationDialog}
      onClose={() =>
        dispatch({
          type: 'budgetMutationDialog',
          payload: !poFormState.budgetMutationDialog,
        })
      }
      sections={{
        header: { title: 'Budget Exceeded' },
        body: () => (
          <>
            <TextField
              label="Remark"
              margin="normal"
              fullWidth
              autoComplete="off"
              multiline
              onChange={e =>
                dispatch({ type: 'remarks', payload: e.target.value })
              }
              value={poFormState.remarks}
            />
            <FileUploadInput
              label="Upload Attachment"
              files={files}
              onHandleUploadChange={handleUploadChange}
              multiple
              imagePreview={
                <>
                  {previewFiles?.map((v, i) => (
                    <UploadPreview
                      remove
                      key={v}
                      src={v}
                      onClick={() => remove(i)}
                      mediaType={files[i]?.type}
                    />
                  ))}
                </>
              }
            />
          </>
        ),
        footer: {
          actions: [
            {
              displayText: 'Close',
              props: {
                onClick: () =>
                  dispatch({ type: 'budgetMutationDialog', payload: false }),
                variant: 'contained',
                color: 'primary',
              },
            },
            {
              displayText: 'Proceed',
              props: {
                onClick: () => {
                  purchaseBudgetApproval({
                    variables: {
                      IDs: purchaseBudget
                        ?.filter(v => v?.isExceeded === true)
                        .map(el => el.PRID),
                      input: {
                        remarks: poFormState.remarks,
                        file: files,
                      },
                    },
                  });
                },
                variant: 'contained',
                color: 'primary',
              },
            },
          ],
        },
      }}
    />
  );
};

const POItemDetails = ({ el, index, getWBSParentChild }) => {
  const selectedUnitPrice = !!el?.negoSupplySelected
    ? el?.negoSupplySelected?.unitPrice
    : !!el?.rfqSubmissionSelected
    ? el?.rfqSubmissionSelected?.unitPrice
    : 0;

  // CAN ALSO GRAB BASE AMT USING THIS METHOD
  // const itemBaseAmt = el?.totalAmt;
  const itemBaseAmt =
    parseFloat(amtNumStr(selectedUnitPrice)) *
    (el?.latestQty ?? el?.outstandingQty);

  const itemTotalAmt = itemBaseAmt + el?.taxAmt;

  return (
    <ListItem className="table-listItem " key={index}>
      <ListItemText
        primary={
          <>
            <Grid container className="desc fw-medium">
              <Grid item xs={1} className="mdDesc m-t-10" component="span">
                {index + 1}.
              </Grid>
              <Grid
                item
                xs={11}
                component="div"
                className="m-t-10 xsTitle text-noflow"
              >
                {el?.costItem?.name}
              </Grid>
            </Grid>
            <Grid container className="m-t-8 desc fw-medium">
              <Grid item xs={1} component="span" />
              <Grid item xs={3} component="span" className="left-text">
                {amtStr(selectedUnitPrice)}
              </Grid>
              <Grid item xs={3} component="span" className="left-text">
                {el?.taxCode}
                <TextSeparator />
                {`${el?.taxRate}%`}
              </Grid>
              <Grid item xs={3} component="span" className="center-text">
                {amtStr4Dec(el?.latestQty ?? el?.outstandingQty)}
              </Grid>
              <Grid item xs={2} component="span" className="right-text">
                {el?.uom.code}
              </Grid>
            </Grid>
          </>
        }
        secondary={
          <div className="c-black">
            {!!el?.wbsID && getWBSParentChild?.length > 0 && (
              <Grid container className="m-t-5">
                <Grid item xs={1} component="span" />
                <Grid item xs={11} component="div" className="xxTitle">
                  WBS:
                  <span className="desc fw-medium p-l-3">
                    {getWBSParentChild?.find(x => x?.ID === el?.wbsID)?.path ||
                      'N/A'}
                  </span>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              className={
                !!el?.wbsID && getWBSParentChild?.length > 0 ? 'm-t-2' : 'm-t-5'
              }
            >
              <Grid item xs={1} component="span" />
              <Grid item xs={4} component="span" className="desc left-text">
                <div className="xxTitle">Base Amt</div>
                <div className="fw-medium">{amtStr(itemBaseAmt)}</div>
              </Grid>
              <Grid item xs={3} component="span" className="desc center-text">
                <div className="xxTitle">Tax Amt</div>
                <div className="fw-medium">{amtStr(el?.taxAmt)}</div>
              </Grid>
              <Grid item xs={4} component="span" className="desc right-text">
                <div className="xxTitle">Amt</div>
                <div className="fw-medium">{amtStr(itemTotalAmt)}</div>
              </Grid>
            </Grid>
            <Grid container className="m-t-8">
              <Grid item xs={1} component="span" />
              <Grid item xs={11} component="div">
                <span className="desc text-noflow text-multiline">
                  {el?.remarks ?? 'No Remarks'}
                </span>
              </Grid>
            </Grid>
          </div>
        }
      />
    </ListItem>
  );
};
