import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import { MainHeader } from '@ifca-root/react-component/src/components/Header/MainHeader';
import { SearchFilterV2 } from '@ifca-root/react-component/src/components/SpecialBar/SearchFilter/SearchFilterV2';
import { Menu, MenuItem } from '@material-ui/core';
import { ContPermission, PurchaseStatus } from 'generated/graphql';
import { PermProps } from 'helpers/Hooks/usePermissionChecker';
import { IAction } from 'models';
import React, { Dispatch, MouseEvent, SetStateAction } from 'react';

interface HeaderProps {
  smTitle: string;
  type: string;
  getContract: any;
  mode: string;
  handleSearch: any;
  history: any;
  routeID: any;
  getSearchDropdown: string;
  setPurchaseStatus: Dispatch<SetStateAction<string>>;
  searchInput: any;
  onChangeSearchInput: (e: any) => void;
  onCloseSearchInput: () => void;
  dropdownEl: any;
  selectedOption: any;
  handleOpenDropdownEl: (event: MouseEvent<HTMLElement>) => void;
  handleCloseDropdownEl: () => void;
  handleOptionChange: (event: any, option: any) => void;
  onClickFilter: any;
  filterDialog: any;
  prGenForm?: boolean;
}

export const MainHeaderPO = ({
  getContract,
  handleSearch,
  mode,
  type,
  smTitle,
  history,
  getSearchDropdown,
  setPurchaseStatus,
  routeID,
  searchInput,
  onChangeSearchInput,
  onCloseSearchInput,
  dropdownEl,
  selectedOption,
  handleOpenDropdownEl,
  handleCloseDropdownEl,
  handleOptionChange,
  onClickFilter,
  filterDialog,
  prGenForm,
}: HeaderProps) => {
  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  const PROJECT_PURCHASE_PATH = `/client-contract/${routeID}/project-purchase`;

  const exitPath = `${PROJECT_PURCHASE_PATH}${
    prGenForm
      ? type === 'po'
        ? '/purchase-order'
        : `/purchase-order/on-behalf`
      : ''
  }`;

  return (
    <>
      <MainHeader
        onClick={e => {
          localStorage.removeItem('searchFilter');
          localStorage.removeItem('searchDropdown');
          history.push({ pathname: exitPath });
          e.stopPropagation();
        }}
        mainBtn={prGenForm ? 'close' : 'back'}
        sideBtn={prGenForm ? '' : 'home'}
        onClickAction={() => {
          localStorage.removeItem('searchFilter');
          localStorage.removeItem('searchDropdown');
          history.push({ pathname: `/` });
        }}
        smTitle={smTitle}
        title={user?.companyName}
        currency={user?.companyCurrencyCode}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Client Contracts' },
          { name: `Purchasing` },
          {
            name: `${
              type === 'direct-po' || type === 'direct-behalf'
                ? 'Direct PO'
                : 'Generate PO from PR'
            }
        ${type === 'po' || type === 'direct-po' ? '' : '(On Behalf)'}`,
            current: true,
          },
        ]}
        rightRouteSegments={
          prGenForm ? [{ name: 'PR Selection', current: true }] : [{}]
        }
      />
      <DynamicSubHeader
        title={getContract[0]?.title}
        infoLine={getContract[0]?.customerDetail?.name}
      />

      <SearchFilterV2
        search
        customMarginTop="45px"
        searchInput={searchInput}
        onChangeSearchInput={onChangeSearchInput}
        onCloseSearchInput={onCloseSearchInput}
        dropdownBorderRadius="4px"
        dropdownOptions={
          mode !== 'client'
            ? [
                { name: 'Draft', ID: 'ACTIVE' },
                { name: 'Submitted', ID: 'SUBMIT' },
                { name: 'Approved', ID: 'APPROVED' },
                { name: 'Rejected', ID: 'REJECTED' },
                { name: 'Cancelled', ID: 'CANCELLED' },
                { name: 'Closed', ID: 'CLOSED' },
                { name: 'All', ID: 'ALL' },
              ]
            : [
                { name: 'Active', ID: 'ACTIVE' },
                { name: 'Closed', ID: 'CLOSED' },
              ]
        }
        dropdownEl={dropdownEl}
        selectedOption={selectedOption}
        handleOpenDropdownEl={handleOpenDropdownEl}
        handleCloseDropdownEl={handleCloseDropdownEl}
        handleOptionChange={handleOptionChange}
        filter
        onClickFilter={onClickFilter}
      />

      {filterDialog}
    </>
  );
};

interface POListingMenu {
  history: any;
  routeID: any;
  type: string;
  anchorEl: HTMLElement;
  menu: {
    ID: string;
    index: number;
    obj: any;
  };
  handleClose: () => void;
  resetMenu: () => void;
  approvableState: boolean;
  handlePermDisabled: ({ contractID, permEnum }: PermProps) => boolean;
  dispatch: Dispatch<IAction<string, any>>;
  checkingCancelClosePO: any[];
  validateContract: boolean;
}

export const POListingMenu = (props: POListingMenu) => {
  const {
    anchorEl,
    approvableState,
    handleClose,
    menu,
    resetMenu,
    history,
    routeID,
    type,
    handlePermDisabled,
    dispatch,
    checkingCancelClosePO,
    validateContract,
  } = props;

  const PROJECT_PURCHASE_PATH = `/client-contract/${routeID}/project-purchase`;

  const handleCloseDialog = () => {
    if (checkingCancelClosePO?.length > 0)
      dispatch({ type: 'pendingDialog', payload: true });
    else dispatch({ type: 'openCloseDialog', payload: true });
  };

  return (
    <>
      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClick={() => handleClose()}
        onClose={() => {
          handleClose();
          resetMenu();
        }}
      >
        {menu?.obj?.purchaseStatus === PurchaseStatus.Active && (
          <>
            <MenuItem
              onClick={() =>
                history.push({
                  pathname: `${PROJECT_PURCHASE_PATH}/${
                    type === 'po'
                      ? `purchase-order/${menu?.ID}/draft`
                      : `purchase-order/on-behalf/${menu?.ID}/draft`
                  }`,
                  state: menu?.obj,
                })
              }
              disabled={
                handlePermDisabled({
                  permEnum:
                    type === 'po'
                      ? ContPermission.ProjectPurchasePurchaseOrderUpdate
                      : ContPermission.ProjectPurchasePurchaseOrderBehalfUpdate,
                }) || !validateContract
              }
            >
              <span>Edit</span>
            </MenuItem>
            <MenuItem
              disabled={!validateContract}
              onClick={() => {
                dispatch({ type: 'openDeleteDialog', payload: true });
              }}
            >
              <span className="">Delete</span>
            </MenuItem>
          </>
        )}

        {menu?.obj?.purchaseStatus !== PurchaseStatus.Active && (
          <MenuItem
            onClick={() =>
              history.push(
                `${PROJECT_PURCHASE_PATH}/${
                  type === 'On Behalf'
                    ? `purchase-order/on-behalf/detail/${menu.ID}`
                    : `purchase-order/detail/${menu.ID}`
                }`,
              )
            }
            disabled={handlePermDisabled({
              permEnum:
                type === 'po'
                  ? ContPermission.ProjectPurchasePurchaseOrderView
                  : ContPermission.ProjectPurchasePurchaseOrderBehalfView,
            })}
          >
            <span className="">View</span>
          </MenuItem>
        )}

        {menu?.obj?.purchaseStatus === PurchaseStatus.Rejected && (
          <>
            <MenuItem
              disabled={
                handlePermDisabled({
                  contractID: '',
                  permEnum:
                    type === 'po'
                      ? ContPermission.ProjectPurchasePurchaseOrderCreate
                      : ContPermission.ProjectPurchasePurchaseOrderBehalfCreate,
                }) || !validateContract
              }
              onClick={() => {
                history.push({
                  pathname: `${PROJECT_PURCHASE_PATH}/purchase-order/${
                    type === 'po'
                      ? `${menu?.ID}/resubmit`
                      : `on-behalf/${menu?.ID}/resubmit`
                  }`,
                  state: menu?.obj,
                });
              }}
            >
              Resubmit
            </MenuItem>
            <MenuItem
              disabled={
                handlePermDisabled({
                  contractID: '',
                  permEnum:
                    type === 'po'
                      ? ContPermission.ProjectPurchasePurchaseOrderApproveReject
                      : ContPermission.ProjectPurchasePurchaseOrderBehalfApproveReject,
                }) || !validateContract
              }
              onClick={() => {
                dispatch({ type: 'openCancelDialog', payload: true });
              }}
            >
              Cancel
            </MenuItem>
          </>
        )}
        {(menu?.obj?.purchaseStatus === PurchaseStatus.Submit ||
          menu?.obj?.purchaseStatus === PurchaseStatus.Pending) && (
          <MenuItem
            onClick={() => {
              dispatch({ type: 'openApproveReject', payload: true });

              handleClose();
            }}
            disabled={
              handlePermDisabled({
                permEnum:
                  type === 'po'
                    ? ContPermission.ProjectPurchasePurchaseOrderApproveReject
                    : ContPermission.ProjectPurchasePurchaseOrderBehalfApproveReject,
              }) ||
              !approvableState ||
              !validateContract
            }
          >
            <span className="">Approve/Reject</span>
          </MenuItem>
        )}

        {menu?.obj?.purchaseStatus === PurchaseStatus.Approved && (
          <MenuItem
            onClick={() => {
              handleCloseDialog();
            }}
            disabled={
              handlePermDisabled({
                permEnum:
                  type === 'po'
                    ? ContPermission.ProjectPurchasePurchaseOrderClose
                    : ContPermission.ProjectPurchasePurchaseOrderBehalfClose,
              }) || !validateContract
            }
          >
            <span className="">Close</span>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
