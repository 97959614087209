export const externalHomeRoutes = () => {
  let siteReactUrl,
    clientReactUrl,
    subcontractorReactUrl,
    supplierReactUrl,
    workdeskReactUrl;
  switch (process.env.REACT_APP_LOCAL_DB) {
    case 'local':
      siteReactUrl = 'http://localhost:3125';
      clientReactUrl = 'http://localhost:3250';
      subcontractorReactUrl = 'http://localhost:3500';
      supplierReactUrl = 'http://localhost:3750';
      workdeskReactUrl = 'http://localhost:3210';
      break;
    case 'report':
      siteReactUrl = 'http://localhost:3125';
      clientReactUrl = 'http://localhost:3250';
      subcontractorReactUrl = 'http://localhost:3500';
      supplierReactUrl = 'http://localhost:3750';
      workdeskReactUrl = 'http://localhost:3210';
      break;
    case 'server':
      siteReactUrl = 'http://localhost:3135';
      clientReactUrl = 'http://localhost:3250';
      subcontractorReactUrl = 'http://localhost:3500';
      supplierReactUrl = 'http://localhost:3750';
      workdeskReactUrl = 'http://localhost:3210';
      break;
    case 'azure':
      siteReactUrl = 'https://sitex-dev.ifca.io';
      clientReactUrl = 'https://clientx-dev.ifca.io';
      subcontractorReactUrl = 'https://subcontractorx-dev.ifca.io';
      supplierReactUrl = 'https://supplierx-dev.ifca.io';
      workdeskReactUrl = 'https://workdeskx-dev.ifca.io';
      break;
    case 'staging':
      siteReactUrl = 'https://sitex.ifca.io';
      clientReactUrl = 'https://clientx.ifca.io';
      subcontractorReactUrl = 'https://subcontractorx.ifca.io';
      supplierReactUrl = 'https://supplierx.ifca.io';
      workdeskReactUrl = 'https://workdeskx-dev.ifca.io';
      break;
    case 'uat':
      siteReactUrl = 'https://site-uat.contractx.asia';
      clientReactUrl = 'https://client-uat.contractx.asia';
      subcontractorReactUrl = 'https://subcontractor-uat.contractx.asia';
      supplierReactUrl = 'https://supplier-uat.contractx.asia';
      workdeskReactUrl = 'https://workdeskx-uat.ifca.io';
      break;
    default:
      siteReactUrl = 'https://site.contractx.asia';
      clientReactUrl = 'https://client.contractx.asia';
      subcontractorReactUrl = 'https://subcontractor.contractx.asia';
      supplierReactUrl = 'https://supplier.contractx.asia';
      workdeskReactUrl = 'https://workdeskx.ifca.io';
      break;
  }

  return {
    siteReactUrl,
    clientReactUrl,
    subcontractorReactUrl,
    supplierReactUrl,
    workdeskReactUrl,
  };
};

export const externalAccountXRoutes = () => {
  let generalLedgerUrl,
    cashBookUrl,
    acctReceivableUrl,
    acctPayableUrl,
    invControlUrl,
    genPurchaseUrl;
  switch (process.env.REACT_APP_LOCAL_DB) {
    case 'local':
      generalLedgerUrl = 'http://localhost:5005';
      cashBookUrl = 'http://localhost:5005';
      acctReceivableUrl = 'http://localhost:5005';
      acctPayableUrl = 'http://localhost:5005';
      invControlUrl = 'http://localhost:5005';
      genPurchaseUrl = 'http://localhost:5005';
      break;
    case 'report':
      generalLedgerUrl = 'http://localhost:3125';
      cashBookUrl = 'http://localhost:5005';
      acctReceivableUrl = 'http://localhost:5005';
      acctPayableUrl = 'http://localhost:5005';
      invControlUrl = 'http://localhost:5005';
      genPurchaseUrl = 'http://localhost:5005';
      break;
    case 'server':
      generalLedgerUrl = 'http://localhost:5005';
      cashBookUrl = 'http://localhost:5005';
      acctReceivableUrl = 'http://localhost:5005';
      acctPayableUrl = 'http://localhost:5005';
      invControlUrl = 'http://localhost:5005';
      genPurchaseUrl = 'http://localhost:5005';
      break;
    case 'azure':
      generalLedgerUrl = 'https://accountx-dev.ifca.io';
      cashBookUrl = 'https://accountx-dev.ifca.io';
      acctReceivableUrl = 'https://accountx-dev.ifca.io';
      acctPayableUrl = 'https://accountx-dev.ifca.io';
      invControlUrl = 'https://accountx-dev.ifca.io';
      genPurchaseUrl = 'https://accountx-dev.ifca.io';
      break;
    case 'staging':
      generalLedgerUrl = 'https://accountx.ifca.io';
      cashBookUrl = 'https://accountx.ifca.io';
      acctReceivableUrl = 'https://accountx.ifca.io';
      acctPayableUrl = 'https://accountx.ifca.io';
      invControlUrl = 'https://accountx.ifca.io';
      genPurchaseUrl = 'https://accountx.ifca.io';
      break;
    case 'uat':
      generalLedgerUrl = 'https://accountx.ifca.io';
      cashBookUrl = 'https://accountx.ifca.io';
      acctReceivableUrl = 'https://accountx.ifca.io';
      acctPayableUrl = 'https://accountx.ifca.io';
      invControlUrl = 'https://accountx.ifca.io';
      genPurchaseUrl = 'https://accountx.ifca.io';
      break;
    default:
      generalLedgerUrl = 'https://home.accountx.asia';
      cashBookUrl = 'https://home.accountx.asia';
      acctReceivableUrl = 'https://home.accountx.asia';
      acctPayableUrl = 'https://home.accountx.asia';
      invControlUrl = 'https://home.accountx.asia';
      genPurchaseUrl = 'https://home.accountx.asia';
      break;
  }

  return {
    generalLedgerUrl,
    cashBookUrl,
    acctReceivableUrl,
    acctPayableUrl,
    invControlUrl,
    genPurchaseUrl,
  };
};

export const externalHrXRoutes = () => {
  let hrEmployeeUrl;
  switch (process.env.REACT_APP_LOCAL_DB) {
    case 'local':
      hrEmployeeUrl = 'http://localhost:3005';
      break;
    case 'report':
      hrEmployeeUrl = 'http://localhost:3005';
      break;
    case 'server':
      hrEmployeeUrl = 'http://localhost:3005';
      break;
    case 'azure':
      hrEmployeeUrl = 'https://hrx-dev-employee.ifca.io';
      break;
    case 'staging':
      hrEmployeeUrl = 'https://hrx-uat-employee.ifca.io';
      break;
    case 'uat':
      hrEmployeeUrl = 'https://hrx-uat-employee.ifca.io';
      break;
    default:
      hrEmployeeUrl = 'https://hrms.hrx.asia';
      break;
  }

  return { hrEmployeeUrl };
};
