import React, { lazy } from 'react';
import purchaseReportingRoutes from './DigitalReporting/PurchaseReportingRoutes';
import purchaseReportParameterRoutes from './DigitalReporting/PurchaseReportParameterRoutes';
import { PurchaseOrderFormV3 } from './POModule/PurchaseOrderFormV3';
import { PurchaseOrderPRDetail } from './POModule/PurchaseOrderPRDetail';

const ClientContractList = lazy(() =>
  import('./ClientContractList').then(module => ({
    default: module.ClientContractList,
  })),
);

const ProjectPurchaseSubmenu = lazy(() =>
  import('./ProjectPurchaseSubmenu').then(module => ({
    default: module.ProjectPurchaseSubmenu,
  })),
);

const NegoSupplyListing = lazy(() =>
  import('./NegoSupply/NegoSupplyListing').then(module => ({
    default: module.NegoSupplyListing,
  })),
);

const NegoSupplyFormV2 = lazy(() =>
  import('./NegoSupply/NegoSupplyFormV2').then(module => ({
    default: module.NegoSupplyFormV2,
  })),
);
const NegoSupplyFormEdit = lazy(() =>
  import('./NegoSupply/NegoSupplyFormEdit').then(module => ({
    default: module.NegoSupplyFormEdit,
  })),
);

const NegoSupplyDetail = lazy(() =>
  import('./NegoSupply/NegoSupplyDetail').then(module => ({
    default: module.NegoSupplyDetail,
  })),
);

const PurchaseReqForm = lazy(() =>
  import('./PRModule/PurchaseReqFormV2').then(module => ({
    default: module.PurchaseReqFormV2,
  })),
);

const PurchaseReqListing = lazy(() =>
  import('./PRModule/PurchaseReqListing').then(module => ({
    default: module.PurchaseReqListing,
  })),
);
const PurchaseReqDetail = lazy(() =>
  import('./PRModule/PurchaseReqDetail').then(module => ({
    default: module.PurchaseReqDetail,
  })),
);
const PurchaseReqPreviewPDF = lazy(() =>
  import('./LedgerMobileViewModule/PRPreviewDetailPDF').then(module => ({
    default: module.PRPreviewDetailPDF,
  })),
);

const PurchaseOrderListingV2 = lazy(() =>
  import('./POModule/PurchaseOrderListingV2').then(module => ({
    default: module.PurchaseOrderListingV2,
  })),
);

const PurchaseOrderDetail = lazy(() =>
  import('./POModule/PurchaseOrderDetail').then(module => ({
    default: module.PurchaseOrderDetail,
  })),
);
const POPDFPreviewDetail = lazy(() =>
  import('./LedgerMobileViewModule/POPreviewDetailPDF').then(module => ({
    default: module.POPreviewPDF,
  })),
);

const DirectPoListing = lazy(() =>
  import('./DirectPOModule/DirectPOListing').then(module => ({
    default: module.DirectPoListing,
  })),
);

const DirectPODetail = lazy(() =>
  import('./DirectPOModule/DirectPODetail').then(module => ({
    default: module.DirectPODetail,
  })),
);

const DirectPoForm = lazy(() =>
  import('./DirectPOModule/DirectPOForm').then(module => ({
    default: module.DirectPoForm,
  })),
);

const GoodReturnNoteListing = lazy(() =>
  import('./GRTNModule/GoodReturnNoteListing').then(module => ({
    default: module.GoodReturnNoteListing,
  })),
);

const GoodReturnNoteDetail = lazy(() =>
  import('./GRTNModule/GoodReturnNoteDetail').then(module => ({
    default: module.GoodReturnNoteDetail,
  })),
);

const GoodReturnNoteForm = lazy(() =>
  import('./GRTNModule/GoodReturnNoteForm').then(module => ({
    default: module.GoodReturnNoteForm,
  })),
);

const DeliveryOrderListing = lazy(() =>
  import('./DOModule/DeliveryOrderListing').then(module => ({
    default: module.DeliveryOrderListing,
  })),
);

const DeliveryOrderForm = lazy(() =>
  import('./DOModule/DeliveryOrderForm').then(module => ({
    default: module.DeliveryOrderForm,
  })),
);

const DeliveryOrderDetail = lazy(() =>
  import('./DOModule/DeliveryOrderDetail').then(module => ({
    default: module.DeliveryOrderDetail,
  })),
);

const PRPDFPreviewDetail = lazy(() =>
  import('./LedgerMobileViewModule/PRPDFPreviewDetailV2').then(module => ({
    default: module.PDFPreviewDetail,
  })),
);

const RFQListing = lazy(() =>
  import('./RFQModule/RFQListing').then(module => ({
    default: module.RFQListing,
  })),
);

const RFQDetails = lazy(() =>
  import('./RFQModule/RFQDetails').then(module => ({
    default: module.RFQDetails,
  })),
);

const RFQForm = lazy(() =>
  import('./RFQModule/RFQForm').then(module => ({
    default: module.RFQForm,
  })),
);

const RFQInviteesListing = lazy(() =>
  import('./RFQModule/RFQInvitees/RFQInviteesListing').then(module => ({
    default: module.RFQInviteesListing,
  })),
);

const RFQInviteesForm = lazy(() =>
  import('./RFQModule/RFQInvitees/RFQInviteesForm').then(module => ({
    default: module.RFQInviteesForm,
  })),
);

const QuotedRFQListing = lazy(() =>
  import('./RFQModule/RFQInvitees/QuotedRFQ/QuotedRFQListing').then(module => ({
    default: module.QuotedRFQListing,
  })),
);

const QuotedRFQForm = lazy(() =>
  import('./RFQModule/RFQInvitees/QuotedRFQ/QuotedRFQForm').then(module => ({
    default: module.QuotedRFQForm,
  })),
);

const QuotedRFQDetails = lazy(() =>
  import('./RFQModule/RFQInvitees/QuotedRFQ/QuotedRFQDetails').then(module => ({
    default: module.QuotedRFQDetails,
  })),
);

const RFQEvaluationSelection = lazy(() =>
  import('./RFQModule/RFQEvaluationSelection').then(module => ({
    default: module.RFQEvaluationSelection,
  })),
);

const RFQPreviewDetailPDF = lazy(() =>
  import('./RFQModule/RFQPreviewPDF/RFQPreviewDetailPDF').then(module => ({
    default: module.RFQPreviewDetailPDF,
  })),
);

const RFQNewPDF = lazy(() =>
  import('./RFQModule/RFQPreviewPDF/RFQNewPDF').then(module => ({
    default: module.RFQNewPDF,
  })),
);

const PRRequestedItemForm = lazy(() =>
  import('./PRModule/components/PRRequestedItemForm').then(module => ({
    default: module.PRRequestedItemForm,
  })),
);

const DirectPOItemForm = lazy(() =>
  import('./DirectPOModule/DirectPOItemForm').then(module => ({
    default: module.DirectPOItemForm,
  })),
);

export const CLIENT_CONTRACT = 'client-contract';
export const PROJECT_PURCHASE = 'project-purchase';
export const ID = ':id';
export const NEGO_SUPPLY = 'negotiated-supply';
export const DIRECT_PO = 'direct-po';
export const PURCHASE_REQ = 'purchase-requisition';
export const PURCHASE_ORDER = 'purchase-order';
export const PURCHASE_ON_BEHALF = 'on-behalf';
export const DELIVERY_ORDER = 'delivery-order';
export const GOOD_RETURN_NOTE = 'good-return-note';
export const DIGITAL_REPORTING = 'digital-reporting';
export const DETAIL = 'detail';
export const PROJECT_PURCHASE_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}`;
export const NEGO_SUPPLY_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}/${NEGO_SUPPLY}`;
export const PURCHASE_REQ_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}/${PURCHASE_REQ}`;
export const PURCHASE_ORDER_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}/${PURCHASE_ORDER}`;
export const DIRECT_PO_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}/${DIRECT_PO}`;
export const DELIVERY_ORDER_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}/${DELIVERY_ORDER}`;
export const GOOD_RECEIVE_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}/${GOOD_RETURN_NOTE}`;
export const DIGITAL_REPORTING_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}/${DIGITAL_REPORTING}`;
const ON_BEHALF = 'On Behalf';

export const projectPurchaseRoutes = [
  ...purchaseReportParameterRoutes,
  ...purchaseReportingRoutes,
  {
    props: { exact: true, path: `/${CLIENT_CONTRACT}` },
    component: <ClientContractList />,
  },

  {
    props: { exact: true, path: `${PROJECT_PURCHASE_PATH}` },
    component: <ProjectPurchaseSubmenu />,
  },
  {
    props: {
      exact: true,
      path: `${NEGO_SUPPLY_PATH}`,
    },
    component: <NegoSupplyListing />,
  },
  {
    props: {
      exact: true,
      path: `${NEGO_SUPPLY_PATH}/add`,
    },
    component: <NegoSupplyFormV2 mode="Add" />,
  },
  {
    props: {
      exact: true,
      path: `${NEGO_SUPPLY_PATH}/edit/:negoId`,
    },
    component: <NegoSupplyFormEdit mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path: `${NEGO_SUPPLY_PATH}/detail/:negoId`,
    },
    component: <NegoSupplyDetail />,
  },
  // PR
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}`,
    },
    component: <PurchaseReqListing type="pr" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/${PURCHASE_ON_BEHALF}`,
    },
    component: <PurchaseReqListing type={ON_BEHALF} />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/${DETAIL}/:pId/workdesk`,
    },
    component: <PurchaseReqDetail state="workdesk" type="pr" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/${PURCHASE_ON_BEHALF}/${DETAIL}/:pId/workdesk`,
    },
    component: <PurchaseReqDetail state="workdesk" type={ON_BEHALF} />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/${DETAIL}/:pId`,
    },
    component: <PurchaseReqDetail type="pr" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/${PURCHASE_ON_BEHALF}/${DETAIL}/:pId`,
    },
    component: <PurchaseReqDetail type={ON_BEHALF} />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/add`,
    },
    component: <PurchaseReqForm type="pr" mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/add/item/:itemMode`,
    },
    component: <PRRequestedItemForm transactionType="pr" mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/add/item/:itemMode/:itemID`,
    },
    component: <PRRequestedItemForm transactionType="pr" mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/:pId/edit`,
    },
    component: <PurchaseReqForm type="pr" mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/:pId/edit/item/:itemMode`,
    },
    component: <PRRequestedItemForm transactionType="pr" mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/:pId/edit/item/:itemMode/:itemID`,
    },
    component: <PRRequestedItemForm transactionType="pr" mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/:pId/resubmit`,
    },
    component: <PurchaseReqForm type="pr" mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/:pId/resubmit/item/:itemMode`,
    },
    component: <PRRequestedItemForm transactionType="pr" mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/:pId/resubmit/item/:itemMode/:itemID`,
    },
    component: <PRRequestedItemForm transactionType="pr" mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/add/preview`,
    },
    component: <PRPDFPreviewDetail type="pr" mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/resubmit/preview`,
    },
    component: <PRPDFPreviewDetail type="pr" mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/resubmit/previewv2`,
    },
    component: <PRPDFPreviewDetail type="pr" mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/:pId/preview`,
    },
    component: <PurchaseReqPreviewPDF type="pr" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/${PURCHASE_ON_BEHALF}/:pId/preview`,
    },
    component: <PurchaseReqPreviewPDF type={ON_BEHALF} />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/${PURCHASE_ON_BEHALF}/add/preview`,
    },
    component: <PRPDFPreviewDetail type="pr-onbehalf" mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/${PURCHASE_ON_BEHALF}/resubmit/preview`,
    },
    component: <PRPDFPreviewDetail type="pr-onbehalf" mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/${PURCHASE_ON_BEHALF}/add`,
    },
    component: <PurchaseReqForm type={ON_BEHALF} mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/${PURCHASE_ON_BEHALF}/add/item/:itemMode`,
    },
    component: <PRRequestedItemForm transactionType="On Behalf" mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/${PURCHASE_ON_BEHALF}/add/item/:itemMode/:itemID`,
    },
    component: <PRRequestedItemForm transactionType="On Behalf" mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/${PURCHASE_ON_BEHALF}/:pId/edit`,
    },
    component: <PurchaseReqForm type={ON_BEHALF} mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/${PURCHASE_ON_BEHALF}/:pId/edit/item/:itemMode`,
    },
    component: <PRRequestedItemForm transactionType="On Behalf" mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/${PURCHASE_ON_BEHALF}/:pId/edit/item/:itemMode/:itemID`,
    },
    component: <PRRequestedItemForm transactionType="On Behalf" mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/${PURCHASE_ON_BEHALF}/:pId/resubmit`,
    },
    component: <PurchaseReqForm type={ON_BEHALF} mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/${PURCHASE_ON_BEHALF}/:pId/resubmit/item/:itemMode`,
    },
    component: (
      <PRRequestedItemForm transactionType="On Behalf" mode="resubmit" />
    ),
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_REQ_PATH}/${PURCHASE_ON_BEHALF}/:pId/resubmit/item/:itemMode/:itemID`,
    },
    component: (
      <PRRequestedItemForm transactionType="On Behalf" mode="resubmit" />
    ),
  },

  // *** PO ****//

  // {
  //   props: {
  //     exact: true,
  //     path: `${PURCHASE_ORDER_PATH}`,
  //   },
  //   component: <PurchaseOrderListing type="po" />,
  // },
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}`,
    },
    component: <DirectPoListing type="direct-po" mode="directlisting" />,
  },
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/${PURCHASE_ON_BEHALF}`,
    },
    component: <DirectPoListing type="direct-behalf" mode="directbehalf" />,
  },
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/${DETAIL}/:dpoID`,
    },
    component: <DirectPODetail type="direct-po" />,
  },
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/${PURCHASE_ON_BEHALF}/${DETAIL}/:dpoID`,
    },
    component: <DirectPODetail type="direct-behalf" />,
  },
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/add`,
    },
    component: <DirectPoForm type="direct-po" mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/add/item/:itemMode`,
    },
    component: <DirectPOItemForm transactionType="direct-po" mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/add/item/:itemMode/:itemID`,
    },
    component: <DirectPOItemForm transactionType="direct-po" mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/:dpoID/draft`,
    },
    component: <DirectPoForm type="direct-po" mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/:dpoID/edit/item/:itemMode`,
    },
    component: <DirectPOItemForm transactionType="direct-po" mode="draft" />,
  },
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/:dpoID/edit/item/:itemMode/:itemID`,
    },
    component: <DirectPOItemForm transactionType="direct-po" mode="draft" />,
  },
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/:dpoID/resubmit`,
    },
    component: <DirectPoForm type="direct-po" mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/:dpoID/resubmit/item/:itemMode`,
    },
    component: <DirectPOItemForm transactionType="direct-po" mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/:dpoID/resubmit/item/:itemMode/:itemID`,
    },
    component: <DirectPOItemForm transactionType="direct-po" mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/${PURCHASE_ON_BEHALF}/add`,
    },
    component: <DirectPoForm type="direct-behalf" mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/${PURCHASE_ON_BEHALF}/add/item/:itemMode`,
    },
    component: <DirectPOItemForm transactionType="direct-behalf" mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/${PURCHASE_ON_BEHALF}/add/item/:itemMode/:itemID`,
    },
    component: <DirectPOItemForm transactionType="direct-behalf" mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/${PURCHASE_ON_BEHALF}/:dpoID/draft`,
    },
    component: <DirectPoForm type="direct-behalf" mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/${PURCHASE_ON_BEHALF}/:dpoID/edit/item/:itemMode`,
    },
    component: (
      <DirectPOItemForm transactionType="direct-behalf" mode="draft" />
    ),
  },
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/${PURCHASE_ON_BEHALF}/:dpoID/edit/item/:itemMode/:itemID`,
    },
    component: (
      <DirectPOItemForm transactionType="direct-behalf" mode="draft" />
    ),
  },
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/${PURCHASE_ON_BEHALF}/:dpoID/resubmit`,
    },
    component: <DirectPoForm type="direct-behalf" mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/${PURCHASE_ON_BEHALF}/:dpoID/resubmit/item/:itemMode`,
    },
    component: (
      <DirectPOItemForm transactionType="direct-behalf" mode="resubmit" />
    ),
  },
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/${PURCHASE_ON_BEHALF}/:dpoID/resubmit/item/:itemMode/:itemID`,
    },
    component: (
      <DirectPOItemForm transactionType="direct-behalf" mode="resubmit" />
    ),
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_ORDER_PATH}`,
    },
    component: <PurchaseOrderListingV2 type="po" mode="listing" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_ORDER_PATH}/purchase-order-pr-list`,
    },
    component: <PurchaseOrderPRDetail type="po" mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_ORDER_PATH}/pob-pr-list`,
    },
    component: <PurchaseOrderPRDetail type={ON_BEHALF} mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_ORDER_PATH}/${PURCHASE_ON_BEHALF}`,
    },
    component: <PurchaseOrderListingV2 type={ON_BEHALF} mode="purchasing" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_ORDER_PATH}/${PURCHASE_ON_BEHALF}/${DETAIL}/:pId/workdesk`,
    },
    component: <PurchaseOrderDetail state="workdesk" type={ON_BEHALF} />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_ORDER_PATH}/${DETAIL}/:pId/workdesk`,
    },
    component: <PurchaseOrderDetail state="workdesk" type="po" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_ORDER_PATH}/${DETAIL}/:pId`,
    },
    component: <PurchaseOrderDetail type="po" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_ORDER_PATH}/${PURCHASE_ON_BEHALF}/${DETAIL}/:pId`,
    },
    component: <PurchaseOrderDetail type={ON_BEHALF} />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_ORDER_PATH}/add`,
    },
    component: <PurchaseOrderFormV3 type="po" mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_ORDER_PATH}/${PURCHASE_ON_BEHALF}/add`,
    },
    component: <PurchaseOrderFormV3 type={ON_BEHALF} mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_ORDER_PATH}/:dpoID/resubmit`,
    },
    component: <DirectPoForm type="po" mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_ORDER_PATH}/:dpoID/resubmit/item/:itemMode`,
    },
    component: <DirectPOItemForm transactionType="po" mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_ORDER_PATH}/:dpoID/resubmit/item/:itemMode/:itemID`,
    },
    component: <DirectPOItemForm transactionType="po" mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_ORDER_PATH}/:dpoID/draft`,
    },
    component: <DirectPoForm type="po" mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_ORDER_PATH}/:dpoID/edit/item/:itemMode`,
    },
    component: <DirectPOItemForm transactionType="po" mode="draft" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_ORDER_PATH}/:dpoID/edit/item/:itemMode/:itemID`,
    },
    component: <DirectPOItemForm transactionType="po" mode="draft" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_ORDER_PATH}/${PURCHASE_ON_BEHALF}/:dpoID/resubmit`,
    },
    component: <DirectPoForm type="po-behalf" mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_ORDER_PATH}/${PURCHASE_ON_BEHALF}/:dpoID/resubmit/item/:itemMode`,
    },
    component: <DirectPOItemForm transactionType="po-behalf" mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_ORDER_PATH}/${PURCHASE_ON_BEHALF}/:dpoID/resubmit/item/:itemMode/:itemID`,
    },
    component: <DirectPOItemForm transactionType="po-behalf" mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_ORDER_PATH}/${PURCHASE_ON_BEHALF}/:dpoID/draft`,
    },
    component: <DirectPoForm type="po-behalf" mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_ORDER_PATH}/${PURCHASE_ON_BEHALF}/:dpoID/edit/item/:itemMode`,
    },
    component: <DirectPOItemForm transactionType="po-behalf" mode="draft" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_ORDER_PATH}/${PURCHASE_ON_BEHALF}/:dpoID/edit/item/:itemMode/:itemID`,
    },
    component: <DirectPOItemForm transactionType="po-behalf" mode="draft" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_ORDER_PATH}/:pId/preview`,
    },
    component: <POPDFPreviewDetail type="po" />,
  },
  {
    props: {
      exact: true,
      path: `${PURCHASE_ORDER_PATH}/${PURCHASE_ON_BEHALF}/:pId/preview`,
    },
    component: <POPDFPreviewDetail type="po-behalf" />,
  },

  /////////////////////DIRECT PO PDF//////////////////////////////////////////////

  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/:pId/preview`,
    },
    component: <POPDFPreviewDetail type="direct-po" />,
  },
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/${PURCHASE_ON_BEHALF}/:pId/preview`,
    },
    component: <POPDFPreviewDetail type="direct-behalf" />,
  },

  {
    props: {
      exact: true,
      path: `${GOOD_RECEIVE_PATH}`,
    },
    component: <GoodReturnNoteListing />,
  },
  {
    props: {
      exact: true,
      path: `${GOOD_RECEIVE_PATH}/add`,
    },
    component: <GoodReturnNoteForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `${GOOD_RECEIVE_PATH}/resubmit/:gId`,
    },
    component: <GoodReturnNoteForm mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path: `${GOOD_RECEIVE_PATH}/edit/:gId`,
    },
    component: <GoodReturnNoteForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: `${GOOD_RECEIVE_PATH}/detail/:gId`,
    },
    component: <GoodReturnNoteDetail />,
  },
  {
    props: {
      exact: true,
      path: `${DELIVERY_ORDER_PATH}`,
    },
    component: <DeliveryOrderListing />,
  },
  {
    props: {
      exact: true,
      path: `${DELIVERY_ORDER_PATH}/add`,
    },
    component: <DeliveryOrderForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `${DELIVERY_ORDER_PATH}/resubmit/:dId`,
    },
    component: <DeliveryOrderForm mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path: `${DELIVERY_ORDER_PATH}/edit/:dId`,
    },
    component: <DeliveryOrderForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: `${DELIVERY_ORDER_PATH}/detail/:dId`,
    },
    component: <DeliveryOrderDetail />,
  },
  {
    props: { exact: true, path: `${PROJECT_PURCHASE_PATH}/rfq` },
    component: <RFQListing type="rfq" />,
  },

  {
    props: {
      exact: true,
      path: `${PROJECT_PURCHASE_PATH}/rfq/:rfqID/view`,
    },
    component: <RFQDetails />,
  },

  {
    props: { exact: true, path: `${PROJECT_PURCHASE_PATH}/rfq/add` },
    component: <RFQForm mode="add" />,
  },

  {
    props: {
      exact: true,
      path: `${PROJECT_PURCHASE_PATH}/rfq/:rfqID/edit`,
    },
    component: <RFQForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path: `${PROJECT_PURCHASE_PATH}/rfq/:rfqID/invitees`,
    },
    component: <RFQInviteesListing />,
  },

  {
    props: {
      exact: true,
      path: `${PROJECT_PURCHASE_PATH}/rfq/:rfqID/invitees/add`,
    },
    component: <RFQInviteesForm />,
  },

  {
    props: {
      exact: true,
      path: `${PROJECT_PURCHASE_PATH}/rfq/:rfqID/invitees/:supplierID/quoted-rfq`,
    },
    component: <QuotedRFQListing />,
  },

  {
    props: {
      exact: true,
      path: `${PROJECT_PURCHASE_PATH}/rfq/:rfqID/invitees/:supplierID/quoted-rfq/add`,
    },
    component: <QuotedRFQForm mode="add" />,
  },

  {
    props: {
      exact: true,
      path: `${PROJECT_PURCHASE_PATH}/rfq/:rfqID/invitees/:supplierID/quoted-rfq/edit/:invID`,
    },
    component: <QuotedRFQForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path: `${PROJECT_PURCHASE_PATH}/rfq/:rfqID/invitees/:supplierID/quoted-rfq/view`,
    },
    component: <QuotedRFQDetails />,
  },

  {
    props: {
      exact: true,
      path: `${PROJECT_PURCHASE_PATH}/rfq/:rfqID/Evaluation-&-Selection`,
    },
    component: <RFQEvaluationSelection mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `${PROJECT_PURCHASE_PATH}/rfq/:rfqID/Evaluation-&-Selection/workdesk`,
    },
    component: <RFQEvaluationSelection mode="add" state="workdesk" />,
  },
  {
    props: {
      exact: true,
      path: `${PROJECT_PURCHASE_PATH}/rfq/:rfqID/Evaluation-&-Selection/resubmit`,
    },
    component: <RFQEvaluationSelection mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path: `${PROJECT_PURCHASE_PATH}/rfq/:rfqID/preview`,
    },
    component: <RFQPreviewDetailPDF />,
  },
  {
    props: {
      exact: true,
      path: `${PROJECT_PURCHASE_PATH}/rfq/:rfqID/Evaluation-&-Selection/preview`,
    },
    component: <RFQNewPDF />,
  },
];
export default projectPurchaseRoutes;
