const session = sessionStorage.getItem('accessToken');
let accessToken = session ?? '';

export const setAccessToken = (s: string) => {
  accessToken = s;
};

export const getAccessToken = () => {
  return accessToken;
};
