import templateRoutes from 'components/Template/RoutesTemplate';
import ledgerMobileViewRoutes from 'containers/AccountLedgerModule/AccountLedgerMobileView/LedgerMobileViewRoutes';
import AccountLedgerSubmenuRoutes from 'containers/AccountLedgerModule/AccountLedgerSubmenuRoutes';
import AccountLedgerTransactionRoutes from 'containers/AccountLedgerModule/AccountLedgerTransactionRoutes';
import clientAcctRoutes from 'containers/AssociateAccountModule/ClientAccountRoutes';
import associateRoutes from 'containers/AssociateModule/AssociateRoutes';
import authenticationRoutes from 'containers/Authentication/AccountRoutes';
import calendarRoutes from 'containers/CalendarModule/CalendarRoutes';
import companyRoutes from 'containers/CompanyModule/CompanyRoutes';
import contractRoutes from 'containers/ContractModule/ContractRoutes';
import subcontractRoutes from 'containers/ContractModule/SubcontractRoutes';
import eDocRoutes from 'containers/EDocModule/EDocRoutes';
import fuelConsumptionRoutes from 'containers/FuelConsumptionModule/FuelConsumptionRoutes';
import glInterfaceRoutes from 'containers/GLInterfaceModule/GLInterfaceRoutes';
import generalSettingRoutes from 'containers/GeneralSettingModule/GeneralSettingRoutes';
import costCategoryRoutes from 'containers/GeneralSettingModule/RoutesModule/CostCategoryRoutes';
import groupBIRoutes from 'containers/GroupBIModule/GroupBIModuleMenuRoutes';
import homeRoutes from 'containers/HomeModule/HomeRoutes';
import plantMachineryRoutes from 'containers/PlantMachineryModule/PlantMachineryRoutes';
import profitRecognitionRoutes from 'containers/ProfitRecognitionModule/ProfitRecognitionRoute';
import projectCostingRoutes from 'containers/ProjectCostingModule/ProjectCostingRoutes';
import projectPurchaseRoutes from 'containers/ProjectPurchaseModule/ProjectPurchaseRoutes';
import projectTenderRoutes from 'containers/ProjectTenderModule/ProjectTenderRoutes';
import SubconAccountRoutes from 'containers/SubconAccountModule/SubconAccountRoutes';
import subconTenderRoutes from 'containers/SubconTenderModule/SubconTenderRoutes';
import subcontractorRoutes from 'containers/SubcontractorModule/SubcontractorRoutes';
import supplierRoutes from 'containers/SupplierModule/SupplierRoutes';
import systemAdminRoutes from 'containers/SystemAdminModule/SystemAdminRoutes';
import workRoutes from 'containers/WorkDeskModule/WorkRoutes';
import { Redirect } from 'react-router-dom';
import React from 'react';

const redirectRoute = [
  {
    props: { exact: true, path: '/' },
    component: <Redirect to="/" />,
  },
];

const errorRoute = [{ component: <Redirect to="/authentication/404" /> }];

//error route always at the bottom//
const routes = [
  ...homeRoutes,
  ...companyRoutes,
  ...workRoutes,
  ...eDocRoutes,
  ...ledgerMobileViewRoutes,
  ...SubconAccountRoutes,
  ...AccountLedgerSubmenuRoutes,
  ...AccountLedgerTransactionRoutes,
  ...systemAdminRoutes,
  ...generalSettingRoutes,
  ...subcontractorRoutes, // old design
  ...supplierRoutes,
  ...contractRoutes,
  ...projectPurchaseRoutes,
  ...projectTenderRoutes,
  ...subcontractRoutes,
  ...subconTenderRoutes,
  ...plantMachineryRoutes,
  ...fuelConsumptionRoutes,
  ...projectCostingRoutes,
  ...glInterfaceRoutes,
  ...profitRecognitionRoutes,
  ...authenticationRoutes,
  ...costCategoryRoutes,
  ...associateRoutes,
  ...clientAcctRoutes,
  ...calendarRoutes,
  ...groupBIRoutes,
  ...templateRoutes,
  ...redirectRoute,
  ...errorRoute,
];

export default routes;
